import React, { useState, useEffect } from 'react';

function Modal({ className, children, show, ...eventListeners }) {
	const [ state, setState ] = useState({
		shown: false,
		hidden: true,
	});

	useEffect(() => {
		const body = document.querySelector('body');

		if (show) {
			body.classList.add('modal-open');
		}
		else {
			body.classList.remove('modal-open');
		}

		setTimeout(() => setState({shown: show, hidden: !show}), 0);
	}, [show]);

	return (
		<div>
			<div
				onClick={event => { event.target == event.currentTarget && eventListeners.onHide && eventListeners.onHide(); }}
				className={'modal fade' + (state.shown ? ' show' : '')}
				tabIndex="-1"
				role="dialog"
				style={{display: state.shown ? 'block' : 'none'}}
				aria-modal={state.shown}
				aria-hidden={!state.shown}
			>
				<div className={`modal-dialog ${className}`}>
					<div className="modal-content">
						{state.shown && children}
					</div>
				</div>
			</div>
			{
				show && <div className="modal-backdrop fade show"></div>
			}
		</div>
	);
}

export function ModalHeader({ title, className, ...eventListeners }) {
	return title ? (
		<div className={`modal-header ${className}`}>
			<h5 className="modal-title">{title}</h5>
			<button onClick={event => event.target == event.currentTarget && eventListeners.onHide && eventListeners.onHide()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
		</div>
	) : (
		<button onClick={() => eventListeners.onHide && eventListeners.onHide()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
	);
}

export function ModalFooter({ children, className }) {
	return (
		<div className={`modal-footer ${className}`}>
			{children}
			{/*
			<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
			<button type="button" className="btn btn-primary">Save changes</button>
			*/}
		</div>
	);
}

export function ModalBody({ children }) {
	return (
		<div className="modal-body">
			{children}
		</div>
	);
}

export default Modal;
