import config from '../config';
const { locale, formats } = config.intl;

const currencyFormat = new Intl.NumberFormat(locale, formats.number.currency);
const dateFormat = new Intl.DateTimeFormat(locale, formats.datetime.date);
const datetimeFormat = new Intl.DateTimeFormat(locale, formats.datetime.datetime);

function toDate(value) {
	if (value instanceof Date) {
		return value;
	}

	return isNaN(value) ?
		new Date(value)
		:
		new Date(value.toString().length > 10 ? parseInt(value) : parseInt(value) * 1000);
}

export function PriceFormat({ value }) {
	return currencyFormat.format(value / 100);
}

export function DateFormat({ value }) {
	return dateFormat.format(toDate(value));
}

export function DateTimeFormat({ value }) {
	return datetimeFormat.format(toDate(value));
}
