import React, { useState, useEffect, useContext } from 'react';

import { useQuery, useLazyQuery } from '../hooks/graph';
import { AuthContext } from '../contexts/auth';
const controller = new AbortController();

function MeAccountPay({ onSubmit }) {
	const { user, proxiedUser } = useContext(AuthContext);
	const [ products, setProducts ] = useState();
	const [ account, setAccount ] = useState(); // parsed dataAccount.data
	const [ accountProduct, setAccountProduct ] = useState();
	const { data: dataAccount } = useQuery('account', {fetchPolicy: 'network-only'}, controller);
	const { data: dataProducts } = useQuery('products', controller);
	const [ dataProductsInterval, setDataProductsInterval ] = useState();
	const [ accountPaymentSession ] = useLazyQuery('accountPaymentSession', {fetchPolicy: 'network-only'}, controller);

	useEffect(() => {
		if (dataAccount) {
			setAccount({...dataAccount.account, metadata: JSON.parse(dataAccount.account.metadata)});
			setDataProductsInterval('month'); // TODO temporal
		}
	}, [dataAccount]);

	useEffect(() => {
		if (dataProducts) {
			setProducts(dataProducts.products.map(item => ({...item, metadata: JSON.parse(item.metadata)})));
		}
	}, [dataProducts]);

	useEffect(() => {
		if (!products || !account) {
			return;
		}

		// TODO temporal
		if (account.metadata.recurring) {
			setAccountProduct(products.find(item => item.metadata.stripeProductId === account.metadata.recurring.stripeProductId));
		}
		else {
			setAccountProduct({
				name: 'Free',
				// obsoleted / credits: 5,
			});
		}
	}, [products, account]);

	const onSession = async () => {
		const { data: { accountPaymentSession: session } } = await accountPaymentSession();

		// https://stripe.com/docs/customer-management/portal-deep-links
		window.open(session.url);

		// TODO refresh the page on the "portal" close or back redirect
	};

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return (
		<div>
			{
				account && accountProduct && dataProducts && dataProductsInterval ? (
					<div className="mt-4">
						<div className="card">
							<div className={'card-body text-center'}>
								<div className="h3">{accountProduct?.name || 'Free'}</div>
								<div className="">{account.recurringPeriodCredits} signals / month</div>
								{ account.recurringPeriodEnd ? (<div className="text-secondary">{ account.metadata.recurring?.cancelAt ? <span>Cancel date: {new Date(account.metadata.recurring?.cancelAt * 1000).toDateString()}</span> : <span>Renewal date: {new Date(account.recurringPeriodEnd * 1000).toDateString() }</span> }</div>) : 'Please click the link below to activate the subscription.' }
								<div className="text-secondary">Customer email: {proxiedUser || user.email}</div>
							</div>

							<div className={'card-body bg-light rounded-bottom text-center'}>
								<button onClick={onSession} className="btn btn-primary">Manage subscriptions</button>
							</div>
						</div>
					</div>
				) : false
			}
		</div>
	);
}

export default MeAccountPay;
