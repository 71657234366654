import React from 'react';
import { useForm } from 'react-hook-form';

import { useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
const controller = new AbortController();

function MeSettingsUserAccountReward() {
	const [ adminReferrerCreate, { loading, called, error } ] = useMutation('adminAccountReward', controller);
	const { register, handleSubmit, clearErrors, setError, formState: { errors } } = useForm({
		defaultValues: {credits: 25},
	});

	const onSubmit = values => {
		!loading && adminReferrerCreate({
			variables: values,
		}).then(() => {
			// setState({...state, success: true});

		}).catch(err => {
			const errorMessage = (err.graphQLErrors[0] || {}).message;
			setError('submit', {message: errorMessage && errorMessage !== 'Error' ? errorMessage : 'Something went wrong...'});
		});
	};

	return (
		<div className="mb-4 p-3 bg-light">
			<div>Reward signals</div>

			<form className={'row gx-3 mt-2'} onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
				<div className="col">
					<input placeholder="Email" {...register('email', {required: 'This is required.'})} type="email" id="email" className={formControlInvalid(errors?.name, 'form-control')} />
					{errors?.email && <span className="invalid-feedback">{errors.email.message}</span>}
				</div>

				<div className="col">
					<input placeholder="Credits" {...register('credits', {required: 'This is required.', valueAsNumber: true, validate: value => value > 0})} type="text" id="credits" className={formControlInvalid(errors?.credits, 'form-control')} />
					{errors?.credits && <span className="invalid-feedback">{errors.credits.message}</span>}
				</div>

				<div className="col-auto">
					<button type="submit" className="btn btn-primary">Add</button>
				</div>
			</form>

			{
				called && error && errors.submit && (
					<div className="alert alert-danger mt-3" role="alert">
						{errors.submit.message}
					</div>
				)
			}

			{
				called && !error && (
					<div className="alert alert-success mt-3" role="alert">
						Successfully added
					</div>
				)
			}
		</div>
	);
}

export default MeSettingsUserAccountReward;
