import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import config from '../config';
import { SetupContext } from '../contexts/setup';
import { useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
import Splash, { SplashBody } from '../components/splash';

function AuthPasswordRequest({ location }) {
	const { register, handleSubmit, clearErrors, formState: { errors } } = useForm({
		defaultValues: Object.fromEntries(new URLSearchParams(location.search)),
	});

	const { providers } = useContext(SetupContext);
	const [ authPasswordResetRequest, { loading, called, error } ] = useMutation('authPasswordResetRequest');

	const onSubmit = values => {
		!loading && authPasswordResetRequest({
			variables: {...values, baseUrl: config.base},
		}).then(() => {
			// pass
		}).catch(() => {
			// reserved / setError('submit', {message: 'Error...'});
		});
	};

	return providers ? (
		<Splash>
			<SplashBody>
				<h3 className="text-center mt-3">Request password reset</h3>

				{
					called && !error ? (
						<div className="alert alert-success mt-3" role="alert">
							You will be sent an email with a password reset link,<br/>
							if the provided information is correct.
						</div>
					) : (
						<form className="d-grid" onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
							<div>
								<label className="form-label" htmlFor="email">Email</label>
								<input {...register('email', {required: 'This is required.'})} type="email" id="email" className={formControlInvalid(errors?.email, 'form-control')} />
								{errors?.email && <span className="invalid-feedback">{errors.email.message}</span>}
							</div>

							{/*
							<div className="mt-3">
								<label className="form-label" htmlFor="providerName">Connected brokerage</label>
								<select {...register('providerName', {required: 'This is required.'})} type="providerName" id="providerName" className={formControlInvalid(errors?.providerName, 'form-select')} >
									<option key={null} value={null}>None</option>
									{ Object.values(providers).map(item => <option key={item.name} value={item.name}>{item.title}</option>) }
								</select>
								<small id="providerNameHelp" className="form-text text-muted">The last connected brokerage, if any</small>
								{errors?.providerName && <span className="invalid-feedback">{errors.providerName.message}</span>}
							</div>
							*/}

							<button type="submit" className="btn btn-primary mt-4">Send reset link</button>

							{
								called && errors?.submit && (
									<div className="alert alert-danger mt-3" role="alert">
										{errors.submit.message}
									</div>
								)
							}
						</form>
					)
				}
			</SplashBody>

			{
				<div className="mt-4 text-center text-secondary">
					Remember your password? <Link to="/signin" title="Sign in" className="">Sign&nbsp;in</Link>
				</div>
			}
		</Splash>
	) : false;
}

export default withRouter(AuthPasswordRequest);
