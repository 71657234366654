import React, { useState, useEffect } from 'react';
import { useQuery } from '../hooks/graph';
import config from '../config';

window.mixpanel.init(config.services.mixpanel.token); // experimental

export const SetupContext = React.createContext();

// experimental / rename?
export const SetupProvider = props => {
	const [ state, setState ] = useState({});

	// experimental
	useEffect(() => {
		const couponId = new URL(window.location.href).searchParams.get('coupon');
		couponId && setState({...state, couponId});
	}, []);

	useQuery('providers', {
		onCompleted: ({ providers }) => {
			setState({...state,
				providers: JSON.parse(providers).reduce((prev, curr) => ({...prev, [curr.name]: {
					...curr,
					reference: curr.reference || {},
				}}), {}),
			});
		},
	});

	// obsoleted
	// useQuery('providers', {
	// 	onCompleted: ({ providers }) => {
	// 		// reserved
	// 		// reference.class = {...reference.class,
	// 		// 	values: reference.class?.values.sort((a, b) => classWeights[a] < classWeights[b]) || [],
	// 		// };
	// 		console.log('!!!providers', providers);

	// 		setState({...state,
	// 			providers: JSON.parse(providers).reduce((prev, curr) => ({...prev, [curr.name]: {
	// 				...curr,
	// 				reference: JSON.parse(curr.reference || '{}'),
	// 			}}), {}),
	// 		});
	// 	},
	// });

	return state.providers ? (
		<SetupContext.Provider value={{
			...state,
		}}>
			{props.children}
		</SetupContext.Provider>
	) : false;
};

export default SetupProvider;
