function load_script( src, data = {}, cb = function(){} ) {
  var script = document.createElement('script');script.type = 'text/javascript';script.async=1;script.src = src;script.defer=1;
  for (var key in data){script.dataset[key]=data[key];}
  window.location.host.includes('signalstack.com') && document.getElementsByTagName('head')[0].appendChild( script ); // custom
  script.onload = cb;
}

// reserved / window.onload = function() {
(function() {
  // Reamaze
  window._support = window._support || { 'ui': {}, 'user': {} };

  window._support['account'] = 'signalstack';
  window._support['ui']['contactMode'] = 'mixed';
  window._support['ui']['enableKb'] = 'true';
  window._support['ui']['styles'] = {
    widgetColor: 'rgb(42, 110, 241)',
    gradient: true,
  };
  window._support['ui']['widget'] = {
    icon: 'chat',
    position: 'bottom-right',
    label: {}, // experimental / how to hide the default message completely: "ask us a question?"
    // obsoleted / intrusive
    // label: {
    //   text: 'Let us know if you have any questions! &#128522;',
    //   mode: "notification",
    //   delay: 3,
    //   duration: 30,
    //   sound: true,
    // },
  };
  window._support['apps'] = {
    faq: {"enabled":true},
    recentConversations: {},
    orders: {}
  };

  const triggerEvents = ['mousemove', 'focusin', 'scroll'];

  function loadReamaze() {
    load_script('https://cdn.reamaze.com/assets/reamaze.js');

    triggerEvents.forEach( function(event) {
      document.removeEventListener(event, loadReamaze );
    });
  }

  triggerEvents.forEach( function(event) {
    document.addEventListener(event, loadReamaze );
  });
})();
