import React from 'react';
import { useForm } from 'react-hook-form';

import { useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
const controller = new AbortController();

function MeSettingsReferrerDelete() {
	const [ adminReferrerDelete, { loading, called, error } ] = useMutation('adminReferrerDelete', controller);
	const { register, handleSubmit, clearErrors, setError, formState: { errors, isSubmitSuccessful } } = useForm();

	const onSubmit = values => {
		!loading && adminReferrerDelete({
			variables: values,
		}).then(() => { // reserved: { data: { adminReferrerDelete: referrer }}
			// pass
		}).catch(err => {
			const errorMessage = (err.graphQLErrors[0] || {}).message;
			setError('submit', {message: errorMessage && errorMessage !== 'Error' ? errorMessage : 'Something went wrong...'});
		});
	};

	return (
		<div>
			<form className={'row gx-3 mt-2'} onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
				<div className="col">
					<input placeholder="Link or suffix" {...register('referrer')} type="text" id="referrer" className={formControlInvalid(errors?.referrer, 'form-control')} />
					{errors?.submit && <span className="invalid-feedback">{errors.submit.message}</span>}
				</div>

				<div className="col-auto">
					<button type="submit" className="btn btn-primary" disabled>Check</button>
				</div>

				<div className="col-auto">
					<button type="submit" className="btn btn-danger">Delete</button>
				</div>
			</form>

			{
				called && isSubmitSuccessful && (
					<div className="alert alert-success mt-3" role="alert">
						Deleted the referrer link
					</div>
				)
			}

			{
				called && error && errors.submit && (
					<div className="alert alert-danger mt-3" role="alert">
						{errors.submit.message}
					</div>
				)
			}
		</div>
	);
}

export default MeSettingsReferrerDelete;
