import React, { useState, useEffect } from 'react';

function Offcanvas({ className, children, show, ...eventListeners }) {
	const [ state, setState ] = useState({
		shown: false,
		hidden: true,
	});

	useEffect(() => {
		setTimeout(() => setState({shown: show, hidden: !show}), 0);
	}, [show]);

	// highly experimental: showing / hiding classes
	return (
		<div>
			<div
				onClick={event => { event.target == event.currentTarget && eventListeners.onHide && eventListeners.onHide(); }}
				className={`offcanvas offcanvas-start show ${className} ${state.shown ? 'showing' : 'hiding'}`}
				tabIndex="-1"
				aria-hidden={!state.shown}
			>
				{children}
			</div>
		</div>
	);
}

export function OffcanvasHeader({ title, className, ...eventListeners }) {
	return title ? (
		<div className={`offcanvas-header ${className}`}>
			<h5 className="offcanvas-title">{title}</h5>
			<button onClick={event => event.target == event.currentTarget && eventListeners.onHide && eventListeners.onHide()} type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
		</div>
	) : (
		<button onClick={() => eventListeners.onHide && eventListeners.onHide()} type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
	);
}

// export function OffcanvasFooter({ children, className }) {
// 	return (
// 		<div className={`modal-footer ${className}`}>
// 			{children}
// 			{/*
// 			<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
// 			<button type="button" className="btn btn-primary">Save changes</button>
// 			*/}
// 		</div>
// 	);
// }

export function OffcanvasBody({ className, children }) {
	return (
		<div className={`offcanvas-body ${className}`}>
			{children}
		</div>
	);
}

export default Offcanvas;
