import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
// obsoleted / import { Link, withRouter } from 'react-router-dom';

import { AuthContext } from '../contexts/auth';
// obsoleted / import Grid from '../components/grid';

function Home({ history }) {
	const { isAuthenticated } = useContext(AuthContext);

	useEffect(() => {
		history.replace(isAuthenticated ? '/me' : 'signin'); // move along
	}, [true]);

	return (
		<span></span>
	);

	// obsoleted
	// return (
	//   <Grid>
	//     <Link to="/" className="mt-n1 mx-auto text-reset text-decoration-none">
	//       <h1 className="text-center font-weight-bold">Automate your trade execution in&nbsp;minutes</h1>
	//     </Link>
	//     <div className="text-center font-weight-normal">
	//       <Link to="/docs" target={"_blank"} rel="noopener noreferrer" className="text-decoration-none">Check docs and test your hooks here</Link>
	//     </div>

	//     <div className="mt-5 text-center">
	//       <Link to="/signin" title="Start" className="btn btn-primary mx-auto">Start</Link>
	//     </div>
	//   </Grid>
	// );
}

export default withRouter(Home);
