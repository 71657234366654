import React from 'react';
import { Link } from 'react-router-dom';

function Splash({ header, footer, children }) {
	return (
		<div className="d-flex flex-wrap align-content-center min-vh-100">
			<div className="splash container-sm my-5">

				{ // experimental
					header !== false && (
						<div className="text-center">
							<Link to="/">
								<img src="/static/images/logo-black.svg" style={{width: '12em'}} className="ooopacity-25" />
							</Link>
						</div>
					)
				}

				{ children }

				{ // experimental
					footer !== false && (
						<div className="pt-5 pb-3">
							<p className="text-secondary text-center small">
								&copy; 2023 SignalStack. All Rights Reserved
							</p>
						</div>
					)
				}
			</div>
		</div>
	);
}

export function SplashBody({ children }) {
	return (
		<div className="bg-light rounded-3 py-3 px-0 px-lg-2 mt-5">
			<div className="m-3">
				{ children }
			</div>

			{/*
				// @Dan, Jan24: to remove the image completely. for all the screens
				<div className="row m-2">
					<div className="col-12 col-md-7 col-lg-6 pe-md-4">
						{ children }
					</div>

					<div className="d-none d-md-block col-md-5 col-lg-6 border-start">
						<div className="d-flex align-items-center h-100">
							<img src="/static/images/schema.png" width="100%" className="ps-2" />
						</div>
					</div>
				</div>
			*/}
		</div>
	);
}


export default Splash;
