import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './static/styles/index.scss';
import App from './app';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<StrictMode><App /></StrictMode>);
