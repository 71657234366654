import React, { useContext, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import config from '../config';
import { AuthContext } from '../contexts/auth';
import { SetupContext } from '../contexts/setup';
import { useMutation } from '../hooks/graph';
import Splash from '../components/splash';
import Spinner from '../components/spinner';
const controller = new AbortController();

function MeConnectorCallback({ history, match, location }) {
	const [ state, setState ] = useState();
	const { user } = useContext(AuthContext);
	const { providers } = useContext(SetupContext);
	const [ connectorActivate ] = useMutation('connectorActivate', controller);
	const { providerName } = match.params;

	const onClose = provider => {
		if (!!window.opener && !window.opener.closed) {
			window.close(); // experimental
		}

		else {
			const redirectPath = sessionStorage.getItem('onboarding') ? '/onboarding/connector/' + (provider?.name || '') : '/me/connector';
			history.replace(redirectPath);
		}
	};

	useEffect(() => {
		// identify provider by request param or referrer
		const provider = Object.values(providers).find(item =>
			item.name == providerName || document.referrer.includes(item.authentication?.referrerBase)
		);

		if (!provider) {
			return onClose();
		}

		// obtain provider authorization code
		// see: https://www.oauth.com/oauth2-servers/server-side-apps/authorization-code
		const codeParam = provider.authentication.credentials?.at(0) || 'code';
		const searchParams = new URLSearchParams(location.search);
		const code = searchParams.get(codeParam);

		// experimental
		//
		// 231206 case
		// https://app.signalstack.com/callback?oauth_token=VOlliHMhAmPOUcnRvsthmd4nqaTlXQpWkJqVwY2dWIU%3D&oauth_verifier=Token%20has%20already%20been%20Authorized
		if (provider.name === 'etrade' && searchParams.get('oauth_verifier')?.endsWith?.('already been Authorized')) {
			setState('Token generation failure. Please try again');
			return;
		}

		if (code) {
			connectorActivate({
				variables: {
					providerName: providerName || provider.name,
					credentials: [code],
				}
			}).then(() => {
				// Google Analytics: experimental
				window.gtag?.('config', config.services.gtag.id); // TODO to drop it?
				window.gtag?.('event', 'connector_create', {broker: provider.name});

				onClose(provider);

			}).catch(err => {
				window.mixpanel?.track('Broker connection error', {distinct_id: user.id, broker: provider.name, error: err.message});
				setState(err.message || err);
			});
		}

		else {
			// obsoleted / setState('Invalid authorization code');
			onClose(provider);
		}
	}, [providerName]);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return (
		<Splash>
			{
				state ? (
					<div>
						<div className="alert alert-danger mt-3" role="alert">
							{state}
						</div>

						<div className="mt-3 text-center">
							<Link to="/signin" title="Continue" className="btn btn-primary mx-auto">Continue</Link>
						</div>
					</div>
				) : (
					<div className="mt-3 text-center">
						<Spinner show={true} />
					</div>
				)
			}
		</Splash>
	);
}

export default withRouter(MeConnectorCallback);
