import React, { useState, useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { AuthContext } from '../contexts/auth';
import { useMutation } from '../hooks/graph';
import Offcanvas, { OffcanvasBody, OffcanvasHeader } from '../components/offcanvas';

function NavBarMenu({ user, isAdmin, proxiedUser, onLogout, onHide }) {
	return [
		proxiedUser && (
			<div key="a" className="m-2 px-3 py-2 rounded bg-danger text-white text-break">
				<span>Switched to<br/>{proxiedUser.split('@')[0]}@</span>
				{/* <span title={proxiedUser} className="d-block d-lg-none">@</span> */}
			</div>
		),

		<ul key="b" className="nav nav-pills flex-column m-2 mb-5 mb-lg-auto">
			<li className="nav-item class-popover-dashboard">
				<NavLink to="/me/dashboard" title="Dashboard" className={isActive => 'nav-link' + (isActive ? ' active' : '')} onClick={onHide}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-pulse" viewBox="0 0 16 16">
						<path fillRule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Zm6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z"/>
					</svg>
					<span className="nav-link-text">Dashboard</span>
				</NavLink>
			</li>
			<li className="nav-item class-popover-connector">
				<NavLink to="/me/connector" title="Brokers and Webhooks" className={isActive => 'nav-link' + (isActive ? ' active' : '')} onClick={onHide}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
						<path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
						<path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
					</svg>
					<span className="nav-link-text">Brokers &amp; Webhooks</span>
				</NavLink>
			</li>
			<li className="nav-item class-popover-account">
				<NavLink to="/me/account" title="Account and Billing" className={isActive => 'nav-link' + (isActive ? ' active' : '')} onClick={onHide}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
						<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
					</svg>
					<span className="nav-link-text">Account &amp; Billing</span>
				</NavLink>
			</li>
			<li className="nav-item class-popover-documentation">
				<NavLink to="/me/documentation" title="Documentation" className={isActive => 'nav-link' + (isActive ? ' active' : '')} onClick={onHide}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
					</svg>
					<span className="nav-link-text">Documentation</span>
				</NavLink>
			</li>
			{
				isAdmin && (
					<li className="nav-item">
						<NavLink to="/me/settings" title="Admin settings" className={isActive => 'nav-link' + (isActive ? ' active' : '')} onClick={onHide}>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
								<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
								<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
							</svg>
							<span className="nav-link-text">Admin settings</span>
						</NavLink>
					</li>
				)
			}
		</ul>,

		<ul key="c" className="nav nav-pills flex-column m-2">
			<li className="nav-item">
				<a href="/" className="nav-link" title="Logout" role="button" onClick={onLogout}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
						<path d="M7.5 1v7h1V1h-1z"/>
						<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
					</svg>
					<span className="nav-link-text">Logout</span>
				</a>
			</li>

			{/*
			<li className="nav-item pt-3">
				<a href="/me/documentation" className="nav-link py-0"><small>Webhook Docs</small></a>
			</li>
			*/}

			<li className="nav-item">
				<a href="https://help.signalstack.com" onClick={() => window.mixpanel?.track('External Link Clicked', {distinct_id: user.id, subject: 'General FAQs'})} className="nav-link py-0" target="_blank" rel="noreferrer"><small>General FAQs</small></a>
			</li>

			<li className="nav-item">
				<a href="https://help.signalstack.com/conversations/new" onClick={() => window.mixpanel?.track('External Link Clicked', {distinct_id: user.id, subject: 'Contact Support'})} className="nav-link py-0" target="_blank" rel="noreferrer"><small>Contact Support</small></a>
			</li>

			<li className="nav-item">
				<a href="https://help.signalstack.com/status" onClick={() => window.mixpanel?.track('External Link Clicked', {distinct_id: user.id, subject: 'System Status'})} className="nav-link py-0" target="_blank" rel="noreferrer"><small>System Status</small></a>
			</li>


			<li className="nav-item pt-3">
				<a href="/terms-of-service" onClick={() => window.mixpanel?.track('External Link Clicked', {distinct_id: user.id, subject: 'Terms of service'})} className="nav-link py-0" target="_blank" rel="noreferrer"><small>Terms of service</small></a>
			</li>

			<li className="nav-item">
				<a href="https://signalstack.com/privacy-policy" onClick={() => window.mixpanel?.track('External Link Clicked', {distinct_id: user.id, subject: 'Privacy policy'})} className="nav-link py-0" target="_blank" rel="noreferrer"><small>Privacy policy</small></a>
			</li>

			<li className="nav-item">
				<span className="nav-link disabled"><small>&copy; 2023 SignalStack LLC</small></span>
			</li>
		</ul>
	];
}

function NavBar({ history, menu }) {
	const { deauthenticate, isAdmin, proxiedUser, user } = useContext(AuthContext);
	const [ signout ] = useMutation('signout');

	const [ state, setState ] = useState({offcanvas: false});

	const onHide = () => setState({...state, offcanvas: false});

	const onLogout = event => {
		event.preventDefault();

		signout().then(() => {
			deauthenticate();
		}).catch(() => {});

		history.push('/');
	};

	return [
		<div key="lg" className="d-none d-lg-flex flex-column vh-100 bg-light">
			<header className="bg-dddark py-3 px-4">
				{/* <img src="/static/images/logo-small-black.svg" style={{width: '46%'}} className="my-1 mx-auto d-block d-lg-none" /> */}
				<img src="/static/images/logo-black.svg" style={{width: '10em', /* width: '75%' */}} className="my-1" />
			</header>

			{
				menu !== false && (
					<NavBarMenu user={user} isAdmin={isAdmin} proxiedUser={proxiedUser} onLogout={onLogout} />
				)
			}
		</div>,

		<div key="sm" className="navbar fixed-top d-flex d-lg-none bg-light">
			<div className="container-fluid">
				<a className="navbar-brand" href="#">
					<img src="/static/images/logo-black.svg" style={{width: '65%'}} />
				</a>

				{
					menu !== false && (
						<a onClick={() => setState({...state, offcanvas: !state.offcanvas})} className="navbar-toggler border-0 p-0 ms-auto" type="button" data-bs-toggle="offcanvas" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</a>
					)
				}

				<Offcanvas show={state.offcanvas} onHide={onHide}>
					<OffcanvasHeader title="Menu" onHide={onHide}></OffcanvasHeader>
					<OffcanvasBody className="px-0 pt-0">
						<NavBarMenu user={user} isAdmin={isAdmin} proxiedUser={proxiedUser} onLogout={onLogout} onHide={onHide} />
					</OffcanvasBody>
				</Offcanvas>
			</div>
		</div>
	];
}

export default withRouter(React.memo(NavBar));
export { NavBar, NavBarMenu };

