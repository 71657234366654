import React from 'react';
import { /* Link, */ Redirect, withRouter } from 'react-router-dom';

function SignupSuccess({ location }) {
	return ( <Redirect to={location.state?.from || '/me'} /> );

	// reserved
	// return (
	//   <div>
	//     <p>Success</p>

	//     <Link to="/">Return to homepage</Link>
	//   </div>
	// );
}

export default withRouter(SignupSuccess);
