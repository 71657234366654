import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { AuthContext } from '../contexts/auth';
import { formControlInvalid } from '../components/forms';
// obsoleted / import graph from '../hooks/graph';

function MeSettingsUser() {
	const { setProxiedUser, proxiedUser } = useContext(AuthContext);
	const { register, handleSubmit, clearErrors, reset, formState: { errors, isSubmitted, isSubmitSuccessful, isDirty }} = useForm({
		defaultValues: {email: proxiedUser || ''},
	});

	const onSubmit = values => {
		const email = values.email?.toLowerCase();

		setProxiedUser(email);
		reset({email});
	};

	const onReset = () => {
		setProxiedUser(undefined);
		reset({email: ''});
	};

	return (
		<div className="mb-4 p-3 bg-light">
			<div>Proxied user</div>

			<form className={'row gx-3 mt-2' + (isSubmitted ? ' was-validated' : '')} onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
				<div className="col">
					<input {...register('email')} placeholder="Email" type="email" id="email" className={formControlInvalid(errors?.email, 'form-control')} />
					{errors?.email && <span className="invalid-feedback">{errors.email.message}</span>}
					{isSubmitSuccessful && !isDirty && <span className="valid-feedback">Applied</span>}
				</div>

				<div className="col-auto">
					<button type="submit" className="btn btn-primary">Save</button>
				</div>

				<div className="col-auto">
					<button type="button" className="btn btn-danger" onClick={onReset}>Reset</button>
				</div>
			</form>
		</div>
	);
}

export default MeSettingsUser;
