import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import config from '../config';
import { AuthContext } from '../contexts/auth';
import { useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
import Modal, { ModalBody, ModalHeader } from '../components/modal'; // obsoleted: ModalFooter
import Spinner from '../components/spinner';
const controller = new AbortController();
let providerSearchTimeout;

// TODO think to refactor. weirdly linked (that indirect "ifs") to the outside world
function MeConnectorCreate({ providers, connectors, redirect, title, className, selectedProviderName, isCompact, history, location }) {
	const { user } = useContext(AuthContext);
	const [ connectorCreate ] = useMutation('connectorCreate', controller);
	const [ connectorActivate ] = useMutation('connectorActivate', controller);

	const { register, getValues, handleSubmit, setError, clearErrors, reset, formState: { errors } } = useForm({defaultValues: {demo: 'false'}});
	const [ state, setState ] = useState({show: false});

	// temporal (drop it) #231029
	// submit extra "sub" connector events
	if (window.location.pathname.startsWith('/onboarding')) {
		useEffect(() => {
			state?.provider && window.mixpanel?.track('Onboarding: pre-set broker',
				{distinct_id: user.id, step: 'connectorSelected', broker: state?.provider.name});
		}, [state?.provider]);

		useEffect(() => {
			errors?.submit?.message && window.mixpanel?.track('Onboarding: pre-set broker',
				{distinct_id: user.id, step: 'connectorError', message: errors?.submit?.message});
		}, [errors]);
	}

	useEffect(() => {
		const onDropdownBlur = event => {
			setState(s => (s.show && !s.dropdown.contains(event.target)) ? {...s, show: false} : s);
		};

		setState(s => ({...s, onDropdownBlur}));
	}, []);

	useEffect(() => {
		setState(s => ({...s, provider: providers[selectedProviderName]}));
	}, [selectedProviderName]);

	useEffect(() => {
		const provider = state.provider;

		// experimental / pre-create connector for custom cases
		if (provider?.name === 'robinhood') {
			connectorCreate({
				variables: {
					providerName: provider.name,
					// reserved / demo: values.demo === 'true', // yeah, stick to strings
				}
			}).then(({ data: { connectorCreate: { metadata } } }) => {
				register('key', {value: JSON.parse(metadata || '{}')?.key});
			}).catch(err => {
				const message = err.graphQLErrors ? err.graphQLErrors[0]?.extensions?.exception?.message : err.message; // experimental
				setError('submit', {message: message || 'Error'});
			});
		}
	}, [state.provider]);

	useEffect(() => {
		if (state.show) {
			document.addEventListener('click', state.onDropdownBlur);
			reset(); // critical, as the component stays mounted
		}
		else {
			document.removeEventListener('click', state.onDropdownBlur);
		}
	}, [state.show]);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	const onDropdownToggle = () => {
		setState({...state, show: !state.show});
	};

	const onSearch = value => {
		providerSearchTimeout && clearTimeout(providerSearchTimeout);

		providerSearchTimeout = setTimeout(() => {
			setState(s => ({...s, providerSearchRegex: value ? new RegExp(value, 'i') : undefined}));
			value && window.mixpanel?.people.set({broker_search: value});
		}, 777);
	};

	const onSubmitCreate = (provider, values) => {
		connectorCreate({
			variables: {
				providerName: provider.name,
				demo: values.demo === 'true', // yeah, stick to strings
			}
		}).then(({ data: { connectorCreate: { type, requestUrl } } }) => {
			if (type == 'open') {
				// passing provider details with extra contextual expiring parameters (see Etrade)
				onSubmitCreateOpenAuth(provider, requestUrl);
			}
			else {
				onSubmitCreateBasicAuth(provider, values);
			}

		}).catch(err => {
			// obsoleted / onCancelCreate(); // experimental
			const message = err.graphQLErrors ? err.graphQLErrors[0]?.extensions?.exception?.message : err.message; // experimental
			setError('submit', {message: message || 'Error'});
		});
	};

	const onSubmitCreateBasicAuth = (provider, values) => {
		setState(s => ({...state, isLoading: true}));

		connectorActivate({
			variables: {
				providerName: provider.name,
				credentials: (provider.authentication?.credentials || []).map(item => values[item]),
			}
		}).then(() => {
			setState(s => ({...state, isLoading: false}));

			// Google Analytics: experimental
			window.gtag?.('config', config.services.gtag.id); // TODO to drop it?
			window.gtag?.('event', 'connector_create', {broker: provider.name});

			// reserved / setState(s => ({...state, provider: null}));
			selectedProviderName && history.replace('/me/connector'); // critical
			redirect ? history.push(redirect + '/' + provider.name) : history.go(0); // don't replace "go" with "push"

		}).catch(err => {
			setState(s => ({...state, isLoading: false}));

			const message = err.graphQLErrors ?
				err.graphQLErrors[0]?.extensions?.exception?.message || err.graphQLErrors[0]?.message || 'Error...'
				:
				err.message; // experimental

			setError('submit', {message});
			window.mixpanel?.track('Broker connection error', {distinct_id: user.id, broker: provider.name, error: message});
		});
	};

	const onSubmitCreateOpenAuth = (provider, requestUrl) => {
		// reserved / in case of many "oauth" windows opened by chance
		// if (state.window && !state.window.closed) {
		// 	state.window.close();
		// }

		const connectorWindow = window.open(requestUrl);

		// if popups enabled
		if (connectorWindow) {
			connectorWindow.focus();

			const connectorInterval = setInterval(() => {
				if (!connectorWindow || !!connectorWindow.closed) {
					clearInterval(connectorInterval);
					window.focus(); // experimental

					// reserved / setState(s => ({...state, provider: null}));
					selectedProviderName && history.replace('/me/connector'); // critical
					redirect ? history.push(redirect + '/' + provider.name) : history.go(0); // don't replace "go" with "push"
				}
			}, 1000);

			setTimeout(() => {
				clearInterval(connectorInterval);
				connectorWindow.close();
				// reserved / onCancelCreate();
			}, config.connector.settings.timeout);
		}

		// if popups disabled
		else {
			window.location.assign(requestUrl);
		}
	};

	const onCancelCreate = () => {
		clearErrors('submit');
		setState(s => ({...state, provider: null}));
	};

	const connectorsProviderNames = connectors?.map(item => item.providerName) || [];
	const providersKeys = Object.keys(providers).filter(providerName => providers[providerName].status);

	return (
		<div>
			{
				isCompact ?
					<div>
						{
							providersKeys?.length > 1 ? (
								<div id="connector-create" className="btn-group">
									<button onClick={onDropdownToggle} className={(className || `btn btn-primary`) + ` dropdown-toggle ${state.show ? 'show' : ''}`} ref={dropdown => !state.dropdown && dropdown && setState({...state, dropdown})} type="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
										{ title || 'Connect broker' }
									</button>
									<ul className={`dropdown-menu dropdown-menu-end ${state.show ? 'show' : ''}`} data-bs-popper="static">
										{
											providersKeys.map(item => (
												<li key={item}><button onClick={() => { setState({...state, provider: providers[item]}); }} className="dropdown-item" type="button">{providers[item].title}</button></li>
											))
										}
									</ul>
								</div>
							) : (
								<button onClick={() => { setState({...state, provider: providers[providersKeys[0]]}); }} className={className || 'btn btn-primary'} type="button">
									{ title || 'Connect broker' }
								</button>
							)
						}
					</div>
					:
					<div>
						<div className="input-group">
							<span className="input-group-text" id="provider-search"><img src="/static/images/icons/search.svg" /></span>
							<input onChange={event => onSearch(event.target.value)} type="text" className="form-control" placeholder="Start typing to filter..." aria-label="providerSearch" aria-describedby="provider-search" />
						</div>

						<div className="container-fluid text-center mt-3">
							<div className="row row-cols-2 row-cols-md-3 row-cols-xl-3 g-3 mx-n3">
								{
									providersKeys.filter(item => !state.providerSearchRegex || providers[item].title.match(state.providerSearchRegex)).map(item => (
										<div key={item} onClick={() => { /* weak, experimental or temporal, for onboarding prototype only */ redirect?.startsWith('/onboarding') && connectorsProviderNames.includes(providers[item].name) ? history.replace(redirect + '/' + providers[item].name) : setState({...state, provider: providers[item]}); }} className="col hover" role="button">
											<div className={`border rounded-1 p-2 bg-light bg-opacity-10 hover-opacity-100 ${connectorsProviderNames.includes(item) ? 'border-success' : ''}`}>
												<div className="mx-2 my-3"><img src={`/static/images/providers/${item}.svg`} alt={providers[item].title} className="img-fluid" style={{height: '' + (config.provider.scales[item] || 1) * 2.2 + 'em', marginTop: '' + (1 - (config.provider.scales[item] || 1)) * 2.2 / 2 + 'em', marginBottom: '' + (1 - (config.provider.scales[item] || 1)) * 2.2 / 2 + 'em'}} /></div>
												{ /* <div>{providers[item].title}</div> */ }
												<div className="text-secondary text-truncate"><small>{providers[item].reference?.class?.values.map(classItem => config.order.class.labels[classItem]).join(' \u2022 ')}</small></div>
											</div>
										</div>
									))
								}
							</div>
						</div>
						<div className="mt-3">&nbsp;</div>
					</div>
			}

			{
				state.provider && (
					<Modal show={state.provider} onHide={onCancelCreate} className="modal-lg text-start">{/* keep the pre-alignment as "wrapping" styles interfer with */}
						<ModalHeader title={state.provider.title} onHide={onCancelCreate}></ModalHeader>
						<ModalBody key={state.provider.name}>
							{
								state.provider.authentication.type === 'open' ? (
									<p>Start authorization to create the connection.</p>
								) : (
									<p>Enter your api credentials to create the connection.</p>
								)
							}

							<p className="opacity-50" dangerouslySetInnerHTML={{__html: state.provider.authentication.help || ''}} />

							<form className="d-grid" autoComplete="off" onSubmit={handleSubmit(onSubmitCreate.bind(this, state.provider))} onFocus={() => errors.submit && clearErrors('submit')}>
								{
									state.provider.authentication.demo ? (
										<div className="btn-group w-100 mb-3" role="group" aria-label="Live or Demo">
											<input {...register('demo')} value="false" id="demoFalse" type="radio" autoComplete="off" className="btn-check" />
											<label className="btn btn-outline-secondary" htmlFor="demoFalse">Live</label>

											<input {...register('demo')} value="true" id="demoTrue" type="radio" autoComplete="off" className="btn-check" />
											<label className="btn btn-outline-secondary" htmlFor="demoTrue">Demo</label>
										</div>
									) : false
								}

								{
									state.provider.authentication.type === 'open' ? (
										<div className="d-grid gap-2">
											{/* temporal, Sep23 */}
											{
												state.provider.name === 'tda' ?
													<div className="alert alert-warning mb-0" role="alert">
														As you know, TD Ameritrade and Schwab are merging. As part of their merger, they are be disabled new connection creation. We will connect to Schwab as soon as they are ready. Sorry for the inconvenience.
													</div>
													:
													<button type="submit" className="btn btn-primary btn-block">Continue</button>
											}
										</div>
									) : (
										<div className="d-grid gap-2">
											{
												state.provider.name === 'robinhood' && (
													<div>
														<div>Please use this pre-generated public key for creating API credentials:</div>
														<div><code>{getValues('key') || 'Loading...'}</code></div>
													</div>
												)
											}

											{
												state.provider.authentication.credentials.map((item, index) =>
													<div key={item} className={index ? 'mt-0003' : ''}>
														<label className="form-label text-capitalize" htmlFor={item}>{item.split(/(?=[A-Z])/).join(' ')}</label>
														<input {...register(item, {required: 'This is required.'})} id={item} type={['password'].includes(item) ? 'password' : 'text'} className={formControlInvalid(errors[item], 'form-control')} />
														{errors[item] && <span className="invalid-feedback">{errors[item].message}</span>}
													</div>
												)
											}

											<button type="submit" className="btn btn-primary btn-block mt-3"><Spinner show={state.isLoading}>Continue</Spinner></button>
										</div>
									)
								}
							</form>

							{
								errors?.submit && (
									<div className="alert alert-danger mt-3" role="alert">
										{errors.submit.message}
									</div>
								)
							}

							<div className="alert alert-light mt-4" role="alert">
								<span><a href="mailto:hello@signalstack.com">Contact support</a> for help connecting your broker.</span>
							</div>

							{/*
							reserved
							<div className="mt-3">
								<small className="text-body-secondary">By connecting to the broker I confirm I've read the <a href="/policies/" target="_blank">terms of use</a> and accept all risks.</small>
							</div>
							*/}

							<div className="mt-4 opacity-50" style={{fontSize: '66%'}}>
								<div>
									SignalStack is a tool that gives you the power to full automating order execution with a broker and/or exchange accounts you connect via web hooks. Safely using SignalStack requires a level of skill and experience that not every individual will possess. Automating orders with SignalStack can still be risky for skilled and experienced users.
								</div>

								<div className="mt-1">
									BY USING SIGNALSTACK, YOU ACKNOWLEDGE THAT YOU KNOW, UNDERSTAND, AND APPRECIATE THE RISKS THAT ARE INHERENT IN THE USE OF THE PRODUCT AND NEVERTHELESS VOLUNTARILY AGREE TO ASSUME SUCH RISKS.
								</div>

								<div className="mt-1">
									You hereby forever release and discharge (and agree to fully indemnify and hold harmless) SignalStack, its parent company, associated companies, and employees of SignalStack from any and all claims, demands, suits, causes of action and liabilities, costs, losses, expenses, and damages incurred by or brought or threatened against SignalStack arising from or in connection with your use of SignalStack, including, without limitation, those arising from errors, breaks, or downtime in SignalStack&apos;s system or those of its vendors. You are responsible for monitoring your positions and ensuring that your signals are being successfully processed at all times. By proceeding to connect this broker, you reaffirm that you agree to the preceding disclaimer and that you have read, understand and agree to the SignalStack Terms of Service, which contains additional disclaimers and limitations.
								</div>
							</div>
						</ModalBody>

						{/*
						obsoleted
						<ModalFooter>
							<button type="button" onClick={onCancelCreate} className="btn btn-outline-secondary">Cancel</button>
							<button type="submit"className="btn btn-primary">Submit</button>
						</ModalFooter>
						*/}
					</Modal>
				)
			}
		</div>
	);
}

export default withRouter(MeConnectorCreate);
