import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { useMutation } from '../hooks/graph';
import Splash from '../components/splash';

function AuthEmail({ location }) {
	const [ state, setState ] = useState(false); // success?
	const [ authEmailConfirm, { loading, called } ] = useMutation('authEmailConfirm');

	useEffect(() => {
		!loading && !called && authEmailConfirm({
			variables: {token: new URLSearchParams(location.search).get('token')},
		}).then(() => {
			setState(true);
		}).catch(() => {
			// pass
		});
	}, [loading, called, location.search]);

	return !loading && called ? (
		<Splash>
			<div className="text-center">
				<h3 className="text-center mt-3 d-none">Sign up to SignalStack</h3>
			</div>

			<div className="mt-5">
				{
					state ? (
						<div className="text-center">
							<div className="alert alert-success">
								You have successfully confirmed your email, you can log in now.
							</div>

							<Link to="/signin" className="btn btn-primary btn-block px-5">Login</Link>
						</div>
					) : (
						<div className="text-center">
							<div className="alert alert-danger">
								Error, confirmation link expired or incorrect
							</div>

							{/* <Link to="/" className="btn btn-primary btn-block px-5">Resend</Link> */}
						</div>
					)
				}
			</div>
		</Splash>
	) : false;
}

export default withRouter(AuthEmail);
