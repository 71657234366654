import React from 'react';

import { useLazyQuery } from '../hooks/graph';
import MeSettingsReferrerCreate from './me-settings-referrer-create';
import MeSettingsReferrerDelete from './me-settings-referrer-delete';
const controller = new AbortController();

function MeSettingsReferrer() {
	const [ adminReferrerStatsReportRetrieve, { loading: loadingReferrerReport } ] = useLazyQuery('adminReferrerStatsReport', {
		fetchPolicy: 'network-only',
		onCompleted: ({ adminReferrerStatsReport }) => {
			const url = window.URL.createObjectURL(new Blob(adminReferrerStatsReport));
			const link = document.createElement('a');

			link.href = url;
			link.setAttribute('download', `signalstack-referrers.csv`); // move to the config?
			document.body.appendChild(link);

			link.click();
			link.parentNode.removeChild(link);
		},
	}, controller);

	return (
		<div className="mb-4 p-3 bg-light">
			<div>Referral campaigns</div>

			<div className="">
				<MeSettingsReferrerCreate />
			</div>

			<div className="mt-4">
				<MeSettingsReferrerDelete />
			</div>

			<div className="row gx-3 mt-4">
				{/*
				<div className="col-auto">
					<button type="button" className="btn btn-primary" onClick={() => !loadingReferrerReport && adminReferrerStatsReportRetrieve()}>Download stats</button>
				</div>
				*/}
				<div className="col-auto">
					<button type="button" className="btn btn-primary" onClick={() => !loadingReferrerReport && adminReferrerStatsReportRetrieve()}>Download stats</button>
				</div>
			</div>
		</div>
	);
}

export default MeSettingsReferrer;
