import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';

import config from '../config';
import { useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
import { SetupContext } from '../contexts/setup';
const controller = new AbortController();

function MeSettingsReferrerCreate() {
	const { providers } = useContext(SetupContext);
	const [ adminReferrerCreate, { loading, called, error } ] = useMutation('adminReferrerCreate', controller);
	const { register, handleSubmit, clearErrors, setError, getValues, formState: { errors } } = useForm({
		defaultValues: {credits: 100},
	});

	const [ state, setState ] = useState({
		referrerBase: config.base + '/signup',
		referrer: null,
	});

	const onSubmit = values => {
		!loading && adminReferrerCreate({
			variables: values,
		}).then(({ data: { adminReferrerCreate: referrer }}) => {
			setState({...state, referrer});

		}).catch(err => {
			const errorMessage = (err.graphQLErrors[0] || {}).message;
			setError('submit', {message: errorMessage && errorMessage !== 'Error' ? errorMessage : 'Something went wrong...'});
		});
	};

	return (
		<div>
			<form className={'row gx-3 mt-2'} onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
				<div className="col">
					<input placeholder="Name" {...register('name', {validate: value => getValues('providerName') || value ? undefined : 'Required, if brokerage is not selected', maxLength: 16, pattern: {value: /^[a-zA-Z0-9\s]*$/, message: 'Special characters are not allowed'}})} type="text" id="name" className={formControlInvalid(errors?.name, 'form-control')} />
					<small className="form-text text-muted">Free form name (optional)</small>
					{errors?.name && <span className="invalid-feedback">{errors.name.message}</span>}
				</div>

				<div className="col">
					<select {...register('providerName', /* {required: 'This is required.', validate: (value) => !!value} */)} id="providerName" className={formControlInvalid(errors?.providerName, 'form-select')} >
						<option key="" value="">- None -</option>
						{ Object.values(providers).map(item => <option key={item.name} value={item.name}>{item.title}</option>) }
					</select>
					<small className="form-text text-muted">Brokerage the referral campaign belongs to (optional)</small>
					{errors?.providerName && <span className="invalid-feedback">{errors.providerName.message}</span>}
				</div>

				<div className="col">
					<input placeholder="Credits" {...register('credits', {required: 'This is required.', valueAsNumber: true, validate: value => value > 0})} type="text" id="credits" className={formControlInvalid(errors?.credits, 'form-control')} />
					<small className="form-text text-muted">Extra free signals to be given on signup with</small>
					{errors?.credits && <span className="invalid-feedback">{errors.credits.message}</span>}
				</div>

				<div className="col-auto">
					<button type="submit" className="btn btn-primary">Create</button>
				</div>
			</form>

			{
				called && error && errors.submit && (
					<div className="alert alert-danger mt-3" role="alert">
						{errors.submit.message}
					</div>
				)
			}

			<pre className="alert alert-secondary mt-3" role="alert">
				{
					state?.referrer ? (
						state.referrerBase + (state.referrer ? '/' + state.referrer : '')
					) : (
						'Complete and submit the form to get referrer link'
					)
				}
			</pre>
		</div>
	);
}

export default MeSettingsReferrerCreate;
