import React, { Component } from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import AuthProvider, { RouteAuth } from './contexts/auth';
import SetupProvider from './contexts/setup';

import Home from './containers/home';
import Signin from './containers/signin';
// obsoleted / import SigninCallback from './containers/signin-callback';
import Signup from './containers/signup';
import SignupPasscode from './containers/signup-passcode';
import SignupSuccess from './containers/signup-success';
import SignupPaymentSuccess from './containers/signup-payment-success';
import SigninOAuth from './containers/signin-oauth';
import AuthEmail from './containers/auth-email'; // calback
import AuthPassword from './containers/auth-password'; // calback
import AuthPasswordRequest from './containers/auth-password-request';
import Terms from './containers/terms';

import MeDashboard from './containers/me-dashboard';
import MeConnector from './containers/me-connector';
import MeAccount from './containers/me-account';
import MeSettings from './containers/me-settings';
import MeConnectorCallback from './containers/me-connector-callback';
import MeDocumentation from './containers/me-documentation';
import Navbar from './components/navbar';

const history = createBrowserHistory();

// no replative paths supported? :/
class App extends Component {
	render() {
		return (
			<AuthProvider>
				<Router history={history}>
					<Switch>
						<Route path="/" exact><Home /></Route>
						<Route path="/signin" exact><Signin /></Route>
						<Route path="/signin/oauth" exact><SetupProvider><SigninOAuth /></SetupProvider></Route>
						<Route path="/signup/email" exact><AuthEmail /></Route> {/* temporal: for back compatibility */}
						<RouteAuth path="/signup/passcode" auth={['Customer']} exact><SignupPasscode /></RouteAuth>
						<RouteAuth path="/signup/success" auth={['Customer']} exact><SignupSuccess /></RouteAuth>
						<Route path="/signup/payment/success" exact><SignupPaymentSuccess /></Route>
						<Route path="/signup/:referrer?"><SetupProvider><Signup /></SetupProvider></Route>

						<Route path="/auth/email"><AuthEmail /></Route>
						<Route path="/auth/password" exact><AuthPassword /></Route>
						<Route path="/auth/password/request" exact><SetupProvider><AuthPasswordRequest /></SetupProvider></Route>
						<RouteAuth path="/callback/:providerName?" auth={['Customer']}><SetupProvider><MeConnectorCallback /></SetupProvider></RouteAuth>

						<Route path="/terms-of-service"><Terms /></Route>
						{/* <Route path="/privacy-policy"><Privacy /></Route> */}

						<RouteAuth path="/me" auth={['Customer']}>
							<SetupProvider>
								<nav className="sidebar">
									<Navbar />
								</nav>
								<main>
									<div className="container-fluid">
										<Switch>
											<Route path="/me/dashboard" exact><MeDashboard /></Route>
											<Route path="/me/connector/:providerName?" exact><MeConnector /></Route>
											<Route path="/me/account" exact><MeAccount /></Route>
											<Route path="/me/settings" exact><MeSettings /></Route>
											<Route path="/me/documentation/:providerName?" exact><MeDocumentation /></Route>
											<Route><Redirect to="/me/dashboard" /></Route>
										</Switch>
									</div>
								</main>
							</SetupProvider>
						</RouteAuth>

						<Route><Redirect to="/me" /></Route>

						{/* <RouteAuth path="/admin" auth={['Admin']}><Admin /></RouteAuth> */}
					</Switch>
				</Router>
			</AuthProvider>
		);
	}
}

export default App;
