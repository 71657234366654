import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';

import { AuthContext } from '../contexts/auth';
import { useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
import Splash, { SplashBody } from '../components/splash';

function AuthPassword({ history, location }) {
	const { register, watch, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
	const { isAuthenticated, authenticate } = useContext(AuthContext);
	const [ authPasswordReset, { called, data, error } ] = useMutation('authPasswordReset');
	const token = (new URLSearchParams(location.search)).get('token'); // weak? to useEffect?

	const [ passwordEstimateState, setPasswordEstimateState ] = useState(0);
	const [ authPasswordEstimate ] = useMutation('authPasswordEstimate'); // zxcvbn(password).guesses_log10;
	const watchPassword = watch('password');

	const onSubmit = values => {
		authPasswordReset({
			variables: {...values, token},
		}).then(({ data: { authPasswordReset: authPasswordResetData } }) => {
			authenticate(authPasswordResetData.token);
			history.push('/me');
		}).catch(() => {
			setError('submit', {message: 'We can\'t process the request with these credentials'});
		});
	};

	useEffect(() => {
		if (watchPassword?.length > 3) {
			authPasswordEstimate({variables: {password: watchPassword}}).then(({ data: { authPasswordEstimate: authPasswordEstimateData } }) => {
				setPasswordEstimateState(authPasswordEstimateData);
			});
		}
		else {
			setPasswordEstimateState(0);
		}
	}, [watchPassword]);

	useEffect(() => {
		isAuthenticated && history.replace('/'); // you have to leave
	}, [true]);

	return (
		<Splash>
			<SplashBody>
				<h3 className="text-center mb-3">Reset password</h3>

				{
					called && data && !error ? (
						<div className="alert alert-success" role="alert">
							Thanks for the registration.<br/>
							Please, check for the confirmation email to continue.
						</div>
					) : (
						<form className="d-grid" onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
							<div className="mt-3">
								<label htmlFor="password">New password</label>
								<div className="input-group">
									<input {...register('password', {validate: () => passwordEstimateState > 10})} type="password" id="password" className={formControlInvalid(errors.password, 'form-control')} />
									<a onClick={event => { event.preventDefault(); event.target.closest('.input-group')?.querySelector('input')?.setAttribute('type', 'text'); }} role="button" className="input-group-text"><img src="/static/images/icons/eye.svg" /></a>
								</div>
								{errors.password && <span className="invalid-feedback">Password too weak.</span>}
							</div>

							<div className="mt-3">
								<label htmlFor="password">New password confirmation</label>
								<div className="input-group">
									<input {...register('password2', {validate: value => !watchPassword || value === watchPassword})} type="password" id="password2" className={formControlInvalid(errors.password2, 'form-control')} />
									<a onClick={event => { event.preventDefault(); event.target.closest('.input-group')?.querySelector('input')?.setAttribute('type', 'text'); }} role="button" className="input-group-text"><img src="/static/images/icons/eye.svg" /></a>
								</div>
								{!errors.password && errors.password2 && <span className="invalid-feedback">Password confirmation doesn&apos;t match password.</span>}

								<div className="progress progress-password mt-3" style={{opacity: watchPassword ? 1 : 0}}>
									<div className={'progress-bar ' + (passwordEstimateState > 10 ? 'bg-success' : 'bg-danger')} role="progressbar" style={{width: `${passwordEstimateState * 10}%`}} aria-valuemin="0" aria-valuemax="10"></div>
								</div>
							</div>

							<div className="mt-3">
								<label className="form-label" htmlFor="passcode">Two-factor authentication code</label>
								<input {...register('passcode')} id="passcode" placeholder="Leave blank, if not set" className={formControlInvalid(errors?.passcode, 'form-control')} />
								{errors?.passcode && <span className="invalid-feedback">{errors.passcode.message}</span>}
							</div>

							<button type="submit" className="btn btn-primary mt-4">Continue</button>

							{
								called && error && errors.submit && (
									<div className="alert alert-danger mt-3" role="alert">
										{errors.submit.message}
									</div>
								)
							}
						</form>
					)
				}
			</SplashBody>

			<div className="mt-4 text-center">
				Remember your password? <Link to="/signin" title="Sign in" className="">Sign&nbsp;in</Link>
			</div>
		</Splash>
	);
}

export default withRouter(AuthPassword);
