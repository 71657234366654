import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';

import { useQuery } from '../hooks/graph';

import config from '../config';
import MeAccountPay from './me-account-pay';
import MeAccountPaymentList from './me-account-payment-list';
const controller = new AbortController();

function MeAccount() {
	// reserved
	// const [ stateActions, setStateActions ] = useState({
	//   createdAtFrom: Date.now() - 24 * 60 * 60 * 1000, // 24hr
	// });

	const { data, refetch } = useQuery('account', controller);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return data && data.account ? (
		<div id="account">
			<h3 className="my-3">Account &amp;&nbsp;Billing</h3>

			<div className="row">
				<div className="col-6">
					<div className="bg-light p-4 rounded-3 h-100">
						<h2>{Math.max(data.account.credits || 0, 0)}{/* Yeah, it may "technically" go below zero, cause of "recurrentCredits" */}</h2>
						<h6>Signals left</h6>
					</div>
				</div>
				<div className="col-6">
					<div className="bg-light p-4 rounded-3 h-100">
						<h2>{Math.max((JSON.parse(data.account.metadata)?.creditsTotal || 0) - (data.account.credits || 0), 0)}</h2>
						<h6>Signals spent, total</h6>
					</div>
				</div>
				{/*
				<div className="col-4">
					<div className="bg-light p-4 rounded-3 h-100">
						<h6>&nbsp;</h6>
						<h2>{''}</h2>
					</div>
				</div>
				*/}
			</div>

			{
				data.account.type !== config.account.type.PASSIVE && (
					<div className="mt-3">
						<MeAccountPay onSubmit={refetch} />
					</div>
				)
			}

			<div className="mt-3">
				<MeAccountPaymentList account={data.account} />
			</div>
		</div>
	) : false;
}

export default MeAccount;
