import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';

import { AuthContext } from '../contexts/auth';
import { useMutation, useLazyQuery } from '../hooks/graph';
import { getRedirect } from '../utils/misc';
import { formControlInvalid } from '../components/forms';
import Splash, { SplashBody } from '../components/splash';

function Signin({ history, location }) {
	const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
	const { isAuthenticated, authenticate } = useContext(AuthContext);
	const [ errorState, setErrorState ] = useState(); // workaround, see below
	const [ signin, { loading, called } ] = useMutation('signin');

	const [ getActions, { data: dataActions} ] = useLazyQuery('actions'); // temporal case#230807

	const onSubmit = values => {
		!loading && signin({
			variables: values,
		}).then(({ data: { signin: signinData } }) => {
			sessionStorage.setItem('isPasscode', !!values.passcode); // weeeak / temporal case#230807
			authenticate(signinData.token); // TODO how to not refresh the component?

			// redirect if prompted
			if (location.search.includes('redirect=')) {
				history.push(getRedirect(location.search) || '/');
				// return; // reserved / temporal case#230807
			}

			// reserved / temporal case#230807
			// force 2fa activation
			// if (values.passcode) {
			// 	history.push(location.state?.from?.pathname || '/me');
			// }
			// else {
			// 	history.push({pathname: '/signup/passcode', state: location.state}); // experimental / make passcode to be set
			// }
		}).catch(err => {
			setError('submit', {message: err.message || (err.graphQLErrors || [])[0]?.message || 'We can\'t sign you in with these credentials'});
		});
	};

	// TODO back to game
	// reserved / temporal case#230807
	// useEffect(() => {
	// 	isAuthenticated && history.replace('/me'); // you have to leave
	// }, []);

	// temporal case#230807
	useEffect(() => {
		isAuthenticated && getActions({variables: {code: 'ConnectorActivate'}});
	}, [isAuthenticated]);

	// temporal case#230807
	useEffect(() => {
		if (dataActions) {
			const isPasscode = sessionStorage.getItem('isPasscode') === 'true'; // weeeak / temporal case#230807

			if (dataActions.actions.length) {
				// force 2fa activation
				if (isPasscode) {
					history.push(location.state?.from?.pathname || '/me');
					return;
				}

				history.push({pathname: '/signup/passcode', state: location.state}); // experimental / make passcode to be set
			}

			else {
				history.push('/me/dashboard?welcome');
			}
		}
	}, [dataActions]);

	// workaround, to make "link" work inside the condition, see below
	useEffect(() => {
		errors?.submit && setErrorState(errors?.submit);
	}, [errors?.submit]);

	return (
		<Splash>
			<SplashBody>
				<h3 className="text-center mb-3">Sign in to SignalStack</h3>

				<form className="d-grid" onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
					<div>
						<label className="form-label" htmlFor="email">Email</label>
						<input {...register('email', {required: 'This is required.'})} type="email" id="email" className={formControlInvalid(errors?.email, 'form-control')} />
						{errors?.email && <span className="invalid-feedback">{errors.email.message}</span>}
					</div>

					<div className="mt-3">
						<label className="form-label" htmlFor="password">Password</label>
						<input {...register('password', {required: 'This is required.'})} type="password" id="password" className={formControlInvalid(errors?.password, 'form-control')} />
						{errors?.password && <span className="invalid-feedback">{errors.password.message}</span>}
					</div>

					<div className="mt-3">
						<label className="form-label" htmlFor="passcode">Two-factor authentication code</label>
						<input {...register('passcode')} id="passcode" placeholder="Leave blank, if not set" className={formControlInvalid(errors?.passcode, 'form-control')} />
						{errors?.passcode && <span className="invalid-feedback">{errors.passcode.message}</span>}
					</div>

					<button type="submit" className="btn btn-primary mt-4">Login</button>

					{
						called && errorState && (
							<div className="alert alert-danger mt-3" role="alert">
								{errorState.message}
								<br/>
								<Link to="/auth/password/request" title="Reset password" className="">Forgot your password?</Link>
							</div>
						)
					}
				</form>
			</SplashBody>

			{
				<div className="mt-4 text-center text-secondary">
					Forgot your password? <Link to="/auth/password/request" title="Reset password" className="">Reset&nbsp;password</Link>
				</div>
			}

			{/*
				https://nichetheory.slack.com/archives/C02DWNW5CSK/p1657726366917689

				<div className="mt-4 text-center text-secondary">
					Don't have an account? <Link to={{pathname: '/signup', state: location.state}} title="Signup" className="">Sign&nbsp;up</Link>
				</div>
			*/}
		</Splash>
	);
}

export default withRouter(Signin);
