
export function getRedirect(searchString) {
	const searchParams = new URLSearchParams(searchString);
	return decodeURIComponent(searchParams.get('redirect'));
}

export function addRedirect(url, redirectUrl) {
	return redirectUrl ?
		url + (url.match( /[?]/g ) ? '&' : '?' ) + 'redirect=' + encodeURIComponent(redirectUrl)
		:
		url;
}

export function setClipboard(text) {
	return navigator.clipboard.writeText(text);
}

