// import React from 'react';
// import config from '../config';

export function formControlValid(isValid, className = '') {
	return isValid ? className + ' is-valid' : className;
}

export function formControlInvalid(isInvalid, className = '') {
	return isInvalid ? className + ' is-invalid' : className;
}
