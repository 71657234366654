import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js';

import Modal, { ModalBody, ModalHeader } from '../components/modal';
import { OffcanvasBody, OffcanvasHeader } from '../components/offcanvas';
import { NavBarMenu } from '../components/navbar';
const controller = new AbortController();

const STEPS = [
	'welcome',
	'popover-dashboard',
	'popover-connector',
	'popover-documentation',
	// 'popover-account', // optional
	'connector',
];

const POPOVERS = {
	content: step => `<div class="mt-2 text-end"><button id="id-dashboard-welcome-continue-${step}" type="button" class="btn btn-primary bbbtn-outline-primary btn-sm">Continue</button><div>`,
	options: {
		html: true,
		sanitize: false,
		placement: 'right',
	},
};

let popovers;
let popoversEventListener;

function MeDashboardWelcome({ firstStep }) {

	const [ step, setStep ] = useState(firstStep || 'welcome');
	const [ menu, setMenu ] = useState(null);

	// overcomplicated and fragile: to handle popovers for offcanvas menu views
	useEffect(() => {
		if (step !== 'welcome') {
			return;
		}

		const menuState = document.getElementsByClassName('navbar-toggler-icon')?.[0]?.checkVisibility?.(); // weak
		const menuElement = document.getElementsByClassName(menuState ? 'sidebar-welcome' : 'sidebar')[0].getElementsByClassName(menuState ? 'nav' : 'nav')[0];
		setMenu(menuState);

		popovers = {
			'popover-dashboard': new Popover(menuElement.getElementsByClassName('class-popover-dashboard')[0], {...POPOVERS.options,
				content: 'In the dashboard, you can view a log of all activity in your account, including connected brokers, created webhooks, and sent webhooks.' + POPOVERS.content('popover-dashboard'),
				// title: 'title',
			}),
			'popover-connector': new Popover(menuElement.getElementsByClassName('class-popover-connector')[0], {...POPOVERS.options,
				content: 'Here you can connect brokers and create webhook URL’s.  You will need a unique webhook URL for each account that you wish to have trades sent too.' + POPOVERS.content('popover-connector'),
				// title: 'title',
			}),
			'popover-account': new Popover(menuElement.getElementsByClassName('class-popover-account')[0], {...POPOVERS.options,
				content: 'Here you can manage your subscription.' + POPOVERS.content('popover-account'),
				// title: 'title',
			}),
			'popover-documentation': new Popover(menuElement.getElementsByClassName('class-popover-documentation')[0], {...POPOVERS.options,
				content: 'Here, you can build your order instructions with NO CODE. Simply select your broker and order settings, and the builder will show you an example of how to format your JSON message correctly.' + POPOVERS.content('popover-documentation'),
				// title: 'title',
			})
		};
	}, [step]);

	const onNext = nextStep => {
		// encapsulated to friend with addEventListener
		setStep(prevStep => {

			switch (prevStep) {
				case 'popover-dashboard':
				case 'popover-connector':
				case 'popover-account':
				case 'popover-documentation':
					popoversEventListener && document.
						getElementById('id-dashboard-welcome-continue-' + prevStep).
						removeEventListener('click', popoversEventListener);

					popovers[prevStep]?.hide();
			}

			// cancel
			if (nextStep === null) {
				// pass
			}

			// continue
			else {
				nextStep = nextStep || STEPS[STEPS.indexOf(prevStep) + 1];

				switch (nextStep) {
					case 'popover-dashboard':
					case 'popover-connector':
					case 'popover-account':
					case 'popover-documentation':
						// is already shown? / prevent sides from double-calls of function-based setState
						if (popovers[nextStep]?.tip) {
							break;
						}

						popovers[nextStep]?.show();
						popoversEventListener = () => onNext();

						document.
							getElementById('id-dashboard-welcome-continue-' + nextStep).
							addEventListener('click', popoversEventListener);
				}

				return nextStep;
			}
		});
	};

	useEffect(() => () => {
		for (const item in popovers) {
			popovers[item]?.tip && popovers[item].hide();
			popovers[item]?.disable();
		}
	}, []); // disable popovers on unmount

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return (
		<div>
			{
				step === 'welcome' && (
					<Modal show={true} onHide={() => setStep(null)} className="modal-dialog-centered text-start">{/* keep the pre-alignment as "wrapping" styles interfer with */}
						<ModalHeader title="Welcome" onHide={() => setStep(null)}></ModalHeader>
						<ModalBody>
							<p>Welcome to SignalStack, the automated trading platform that helps you take your trading to the next level.</p>
							<p>This short tour will introduce you to the SignalStack platform and show you how to get started with automated trading.</p>
							<div className="mt-3 text-end"><button type="button" onClick={() => onNext()} className="btn btn-primary bbbtn-outline-primary">Continue</button></div>
						</ModalBody>
					</Modal>
				)
			}

			{
				step === 'connector' && (
					<Modal show={true} onHide={() => setStep(null)} className="modal-dialog-centered text-start">{/* keep the pre-alignment as "wrapping" styles interfer with */}
						<ModalHeader title={firstStep === 'connector' ? 'Welcome' : 'Brokers'} onHide={() => setStep(null)}></ModalHeader>
						<ModalBody>
							<p>Connect a broker to setup automated trading.</p>
							<NavLink to="/me/connector" className="d-grid gap-2"><button className="btn btn-primary btn-block">Continue</button></NavLink>
						</ModalBody>
					</Modal>
				)
			}

			{
				step && (
					<div>
						<nav className={'sidebar sidebar-welcome' + (menu && step?.startsWith('popover') ? '' : ' opacity-0')}>
							<div className="offcanvas offcanvas-start show">
								<OffcanvasHeader title="Menu"></OffcanvasHeader>
								<OffcanvasBody className="px-0 pt-0">
									<div><NavBarMenu /></div>
								</OffcanvasBody>
							</div>
						</nav>

						<div className={'modal-backdrop show' + (step?.startsWith('popover') ? '' : ' d-none')} />
					</div>
				)
			}
		</div>
	);
}

export default MeDashboardWelcome;
