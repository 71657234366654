import React from 'react';

import Splash, { SplashBody } from '../components/splash';

function SignupPaymentSuccess() {

	return (
		<Splash>
			<SplashBody>
				<h3 className="text-center mb-3">Sign up to SignalStack</h3>

				<div className="alert alert-success" role="alert">
					Thanks for the registration and payment.<br/>
					Please, check for the confirmation email to continue.
				</div>
			</SplashBody>
		</Splash>
	);
}

export default SignupPaymentSuccess;
