import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '../hooks/graph';
import Modal, { ModalBody, ModalHeader, ModalFooter } from '../components/modal';
const controller = new AbortController();

function MeConnectorDelete({ providers, connector, onSubmit }) {
	const [ connectorDelete ] = useMutation('connectorDelete', controller);
	const [ getHooks, { data: dataHooks, loading: loadingHooks } ] = useLazyQuery('hooks', controller);
	const [ state, setState ] = useState(false);

	const onSubmitDelete = event => {
		event.preventDefault();

		connectorDelete({
			variables: {id: connector.id}
		}).then(() => {
			onSubmit(); // refetch and more
		}).catch(() => {
			// obsoleted / setError('submit', {message: 'Error: ' + err.message}); // temporal message
		});
	};

	const onCancelDelete = () => {
		setState(false);
	};

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return (
		<span id="connector-delete" className="text-start">
			<a href="#" onClick={event => { event.preventDefault(); getHooks({connectorId: connector.id}); setState(true); }} role="button" className="text-decoration-none">Disconnect</a>

			{
				state && (
					<Modal show={!loadingHooks && dataHooks} onHide={onCancelDelete}>
						<ModalHeader title="Confirmation" onHide={onCancelDelete}></ModalHeader>
						{
							<ModalBody key={connector.id}>
								{
									dataHooks?.hooks?.length ? (
										<div>
											<p>You are about to remove the {providers[connector.providerName].title} (accounts: #{connector.accounts.join(', ')}) brokerage connection.
											Doing so will remove all {dataHooks?.hooks?.lemgth} webhooks connected to this brokerage, which means that they will stop working.</p>

											<p>You won&apos;t be able to restore removed webhooks, but you can create new ones in case if you connect a brokerage again.</p>

											<p>Are you sure you want to proceed?</p>
										</div>
									) : (
										<div>
											<p>Are you sure you want to remove the {providers[connector.providerName].title} (accounts: #{connector.accounts.join(', ')}) brokerage connection?</p>
										</div>
									)
								}
							</ModalBody>
						}
						<ModalFooter>
							<button type="button" onClick={() => { setState(false); }} className="btn btn-secondary">Cancel</button>
							<button type="button" onClick={event => { onSubmitDelete(event); setState(false); }} className="btn btn-danger">Confirm</button>
						</ModalFooter>
					</Modal>
				)
			}
		</span>
	);
}

export default MeConnectorDelete;
