import React, { useState, useEffect } from 'react';

// reserved / import config from '../config';
import { useLazyQuery } from '../hooks/graph';
import { PriceFormat } from '../components/misc';
const controller = new AbortController();

function MeDashboardPaymentList({ account }) {
	const [ payments, setPayments ] = useState();

	const [ accountInvoiceRetrieve, { loading: loadingAccountInvoice } ] = useLazyQuery('accountInvoice', {
		fetchPolicy: 'network-only',
		onCompleted: ({ accountInvoice }) => {
			const url = accountInvoice.invoice_pdf;
			const link = document.createElement('a');

			link.href = url;
			link.setAttribute('download', accountInvoice.id);
			document.body.appendChild(link);

			link.click();
			link.parentNode.removeChild(link);
		},
	}, controller);

	useEffect(() => {
		try {
			setPayments((JSON.parse(account?.metadata) || {}).payments || []);
		}
		catch {
			// pass
		}
	}, [account]);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return payments ? (
		<div className="mb-4">
			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col">Recent payments</th>
						<th scope="col">Date<span className="d-inline d-lg-none">, Invoice</span></th>
						<th scope="col"><span className="d-none d-lg-table-cell">Invoice</span></th>
					</tr>
				</thead>
				{
					payments.length ? (
						<tbody>
							{
								payments.map(item =>
									<tr key={item.createdAt}>
										<td>{item.credits} signals{item.createdAt > 1698811200 ? ' per month' : ''} for {<PriceFormat value={item.price} />}</td>
										<td className="text-nowrap">{new Date((item.createdAt > 1000000000000 ? Math.round(item.createdAt / 1000) : item.createdAt) * 1000).toLocaleDateString()}</td>
										<td>
											<button type="button" onClick={() => !loadingAccountInvoice && accountInvoiceRetrieve({variables: {id: item.stripeInvoiceId}})} href="#" title="Download" className="btn border-0 p-0">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-download" viewBox="0 0 16 16">
													<path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
													<path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
												</svg>
											</button>
										</td>
									</tr>
								)
							}
						</tbody>
					) : (
						<tbody>
							<tr>
								<td colSpan="3" className="text-center">No payments</td>
							</tr>
						</tbody>
					)
				}
			</table>
		</div>
	) : false;
}

export default MeDashboardPaymentList;
