import React, { useState, useEffect } from 'react';

function Spinner({ show, delay, className, children }) {
	delay = typeof delay != 'undefined' ? parseInt(delay) : 500; // in milleseconds / experimental

	const [ state, setState ] = useState({
		show: false,
		showingTimeout: null, // to show with delay
		hidingTimeout: null, // to hide with delay
	});

	// hiding delay helps to prevent "blinking" for cases with
	useEffect(() => {
		let timeout;

		if (show) {
			state.hidingTimeout && clearTimeout(state.hidingTimeout);
			timeout = setTimeout(() => setState({
				showingTimeout: setTimeout(() => setState({show}), delay), // showing delay
			}), 0);
		}

		else {
			state.showingTimeout && clearTimeout(state.showingTimeout);
			timeout = setTimeout(() => setState({
				hidingTimeout: state.show && setTimeout(() => setState({show}), 500), // 500 milliseconds / hiding delay / don't change
			}), 0);
		}

		return () => { timeout && clearTimeout(timeout); };
	}, [show]);

	return (
		<span className={'spinner-wrapper ' + className}>
			<span className={state.show ? 'invisible' : ''}>{ children }</span>
			<span className={state.show ? 'spinner' : 'spinner d-none'}><span className="spinner-border spinner-border-sm" role="status"></span></span>
		</span>
	);
}

export default Spinner;
